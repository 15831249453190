/*
** cookies  warning fixed bootom bar style
*/

.cookies-warn {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: $primary-color;
    box-shadow: 0 -4px 24px 0 rgba(26, 13, 62, 0.54);
    padding:  16px 0;

    @media(max-width: 960px) {
        text-align: center;
    }

    img {
        width: 47px;
        height: 35px;
        vertical-align: middle;
    }

    p {
        display: inline-block;
        margin: 0;
        padding: 0 8px;
        font-size: 16px;
        font-weight: 500;
        color: $white-color !important;
    }

    a {
        font-size: 16px;
        font-weight: 500;
        color: $secondary-color !important;
        text-decoration: underline;
    }

    .cookies-settings {
        display: inline-block;
        padding: 0 8px;

        .v-input {
            display: inline-block;
            margin: 0;
        }

        .v-label {
            color: $white-color;
            font-size: 12px;
        }
    }

    .v-btn {
        font-size: 16px;
        font-weight: 500;
        padding: 0 32px;

        @media(max-width: 960px) {
            margin-top: 16px;
        }
    }
}