// on scrrol animations and hover wffects


/*start navbar links hover effect*/
@keyframes nav-link-hover {
    0% {
        @include gradiant-text;
        background-image: linear-gradient(to right , $secondary-light, $white-color);
        text-shadow: none;
        /* text-shadow: 2px 2px 7px #80DEEA; */
    }

    100% {
        @include gradiant-text;
        background-image: linear-gradient(to right, $secondary-light , $secondary-light);
        text-shadow: 2px 2px 7px $secondary-light;
    }
}
/*end navbar links hover effect*/

/*start view all link hover effect*/
@keyframes view-all-hover {
    0% {
        @include gradiant-text;
        background-image: linear-gradient(to right, $white-color , $accent-color, $accent-color);
    }

    50% {
        @include gradiant-text;
        background-image: linear-gradient(to right, $accent-color , $white-color, $accent-color);
    }

    100% {
        @include gradiant-text;
        background-image: linear-gradient(to right, $accent-color , $accent-color, $white-color);
    }
}
/*end view all link hover effect*/

/*start project card overlay hover effect*/
@keyframes project-overlay {
    0% {
        width: 50px;
        height: 50px;
        background-color: rgba($primary-color, 0);
    }
    
    100% {
        width: 600vw;
        height:600vw;
        background-color: rgba($primary-color, 0.7);
    }
}

/*end project card overlay hover effect*/

/*start project overlay text animation*/
@keyframes project-overlay-links {
    0% {
        transform: translate(200%, 0);
    }

    100% {
        transform: translate(0, 0);
    }
}

@keyframes project-overlay-name {
    0% {
        transform: translate(-100%, 0);
    }

    100% {
        transform: translate(0, 0);
    }
}


/*start footer link hover effect*/
@keyframes footer-link-hover {
    0% {
        @include gradiant-text;
        background-image: linear-gradient(to right, $white-color , $secondary-color, $secondary-color);
    }

    50% {
        @include gradiant-text;
        background-image: linear-gradient(to right, $secondary-color , $white-color, $secondary-color);
    }

    100% {
        @include gradiant-text;
        background-image: linear-gradient(to right, $secondary-color , $secondary-color, $white-color);
    }
}
/*end footer link hover effect*/