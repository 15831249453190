/*
** our services section srtle
*/

.our-services {

    margin-top: 130px;

    @media (max-width: 960px) {
        overflow: hidden;
    }

    @media (max-width: 600px) {
        margin-top: 100px;
    }

    .services-img-container {

        text-align: center;

        .our-services-img {
            max-width: 100%;
        }

        @media (min-width: 960px)  and (max-width: 1263px) {
           /*  padding-inline-end: 100px; */

            .our-services-img {
                max-width: calc(100% - 60px);
                margin-inline-start: -80px;
            }
        }

        @media (min-width: 1264px){
            padding-inline-end: 100px;

            .our-services-img {
                max-width: calc(100% + 70px);
                margin-inline-start: -70px;
            }
        }
    }

    .shapes-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 514px;
        padding-bottom: 120px;
        transform: rotate(-45deg);

        @media (max-width: 960px) {
            margin: 100px auto;
            padding-bottom: 0;
        }

        @media (min-width: 1904px) {
            width: 785px;
        }

        .primary-text {
            line-height: 1.9;
        }

        .our-services-intro, .rotated-shape {
            width: 225px;
            height: 225px;
            margin: 16px;

            @media (min-width: 1904px) {
                width: 360px;
                height: 360px;
            }

            div {
                transform: rotate(45deg);
            }
            
        }

        .our-services-intro {
            .section-title {
                font-size: 30px;
                margin-bottom: 16px;
            }

            span {
                display: inline-block;
            }
            p {
                font-size: 14px;
                margin-top: 16px;
            }
        }

        .rotated-shape {
            display: flex;
            flex-direction: column;
            justify-content: center;
            border-radius: 40px;
            background-color: $display-dark;
            padding: 20px;
            text-align: center;
            color: $white-color;
            font-size: 13px;
            font-weight: 600;
            -webkit-box-shadow: -10px -7px 0px 0px $secondary-color;
            -moz-box-shadow: -10px -7px 0px 0px $secondary-color;
            box-shadow: -10px -7px 0px 0px $secondary-color;

            h3 {
                font-size: 16px;
                margin-bottom: 8px;
            }
        }

        @media (min-width: 600px) and (max-width: 960px)  {
            width: 465px;

            .our-services-intro, .rotated-shape {
                width: 200px;
                height: 200px;
            }

            .rotated-shape {
                font-size: 11px;
            }
        }

        @media (max-width: 600px) {
            display: flex;
            flex-direction: column;
            width: 100%;
            transform: rotate(0deg);
            text-align: center;

            .rotated-shape {
                transform: rotate(-45deg);
                margin: 50px auto;
            }

            .our-services-intro {
                margin: 0 auto;
                /* height: 160px; */
                div {
                    transform: rotate(0deg);
                } 
            }
        }
    }
}

.rtl {
    .our-services {
        .shapes-container {
            transform: rotate(45deg);

            .our-services-intro, .rotated-shape {

                div {
                    transform: rotate(-45deg);
                }
                
            }

            .our-services-intro {
                height: 160px;
            }

            .rotated-shape {    
                -webkit-box-shadow: 10px -7px 0px 0px $secondary-color;
                -moz-box-shadow: 10px -7px 0px 0px $secondary-color;
                box-shadow: 10px -7px 0px 0px $secondary-color;
            }

            @media (max-width: 600px) {

                transform: rotate(0deg);

                .our-services-intro {

                    div {
                        transform: rotate(0deg);
                    }
                    
                }

                .rotated-shape {
                    transform: rotate(45deg);
                    margin: 50px auto;
                }
            }
        }
    }
}