/*
** project details page style
*/

.project-container {
    min-height: 100%;

    .main-footer {
        .devider {
            display: none;
        }
    }
}

.project-cover {
    img {
        width: 100%;
    }

    .v-btn {
        width: 56px !important;
        min-width: unset;
        height: 56px !important;
        position: absolute;
        top: 90px;
        left: 3%;
        background-color: $white-color;
        box-shadow: 0 1px 27.5px 0 rgba(0, 0, 0, 0.3);
        border-radius: 4px;
        min-width: unset !important;
        padding: 0 !important;

        .v-icon {
            color: #d82a65;
        }
    }
}

.project-description {
    margin-top: 64px;

    p {
        margin-bottom: 64px;
    }
}

.project-features {
    margin: 64px 0;

    @media(min-width: 1264px) {
            padding: 0 100px;
    }

    h1 {
        font-size: 21px;
        font-weight: bold;
        color: $secondary-color;
        margin: 0 8px;
        margin-bottom: 16px;
    }

    .feature-card {
        margin: 0 8px;
        margin-top: 32px;
        position: relative;
        box-shadow: 0px 2px 30px 0px rgba(40, 44, 63, 0.07);
        border-radius: 8px;

       /*  &::before {
            position: absolute;
            content: '';
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            box-shadow: 0 70px 350px 0 rgba(0, 0, 0, 0.05);
        }
 */
        figure {
            border-radius: 8px; 
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 1;

            img {
                width: 100%;
                border-radius: 8px 8px 0 0;
            }

            figcaption {
                padding: 16px;
                padding-top: 8px;
                font-size: 18px;
                font-weight: bold;
                text-align: center;

                @media (max-width: 960px) {
                    padding: 8px;
                    font-size: 16px;
                }
 
                &#apricot {
                    color: $apricot-color;
                }

                &#secondary {
                    color: $secondary-color;
                }

                &#morning {
                    color: $morning-color;
                }

                &#golden {
                    color: $golden-color;
                }
            }

        }
    }

    p {
        margin-top: 64px;
        font-size: 16px;
        font-weight: 600;
        line-height: 2.56;
    }
}


.project-links {
    margin-bottom: 64px;

    .app-link {
        display: block;
        width: 100%;

        img {
            width: 100%;
        }

        @media (max-width: 600px) {
            margin-top: 16px;
        }

        @media(min-width: 960px) {
            min-width: 217px !important;
            width: 217px;
        }
    }

    .v-btn {
        font-size: 16px;
        font-weight: bold;
        color: $white-color;

        @media (max-width: 600px) {
            margin-top: 16px;
        }

        @media(min-width: 960px) {
            min-width: 217px !important;
        }
    }

    .project-link {
        display: block;
        margin-top: 64px;
        text-align: center;
        border-radius: 8px;
        padding: 16px;
        margin-top: 16px;
        box-shadow: 0px 2px 30px 0px rgba(40, 44, 63, 0.07);
        font-size: 18px;
        font-weight: bold;
        transition: 0.03s;

        @media(max-width: 600px) {
            font-size: 14px;
        }

        /* &::before {

        } */

        &:hover {
            background-color: $secondary-color;
            color: $white-color;
        }

        &.pdf-link {
            display: flex;
            align-items: center;
            font-size: 24px;
            font-weight: bold;
            color: $secondary-color;
            text-align: unset;
            margin-bottom: 32px;

            @media(max-width: 600px) {
                font-size: 18px;
            }
            span {
                display: inline-block;
                text-align: center;
            }

            &:hover {
                background-color: #fefdfd;
                color: $primary-color;
            }

            img {
                width: 100px;
                margin: 0 16px;
                vertical-align: middle;
                margin-inline-end: 32px;

                @media(max-width: 600px) {
                    width: 80px;
                }
            }
        }
    }
}

#kshop {

    .project-link:not(.pdf-link) {
        margin-top: 0;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .app-link {
        width: 100%;
        height: 100%;
        
        img {
            height: 100%;
        }

        @media(max-width: 959px) {
            margin-top: 16px;
        }
    }
}

/////// rtl style ///////// 
.rtl {
    .project-cover {
        .v-btn {
            right: 3%;
            left: unset;
        }
    }
}