.share-btn {
    min-width: 238px !important;
    margin-bottom: 61px;
    color: $white-color !important;
    font-size: 18px;
    font-weight: bold;

    span {
        padding: 0 8px;
    }
}


.social-sharing-dialog {
    padding: 32px;
    background-color: $white-color;

    .dialog-header { 
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 32px;

        label {
            font-size: 16px;
            font-weight: 500;
            color: $primary-dark;
        }

        a {
            cursor: pointer;
        }
    }

    .socila-sharing-container {
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            color: $white-color;
            outline: none !important;
            cursor: pointer;

            &#email {
                background-color: $gray-color;
            }

            &#facebook {
                background-color: #3b5998;
            }

            &#twitter {
                background-color: #1da1f2;
            }

            &#linkedin {
                background-color: #0077b5;
            }
        }
    }

    .project-link {
        text-align: left;
        margin-top: 16px;
        text-transform: initial;
    }

    .v-btn {
        span {
            padding: 0 8px;
        }
    }
}

.v-snack__content {
    justify-content: center;
    text-align: center;
}