.our-clients {
    margin: 0 auto;
    .clients-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 32px 0;
        flex-wrap: wrap;
        max-width: 72rem;

        .client-shape {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 13rem;
            height: 13rem;
            border-radius: 50%;
            background-color: $white-color;
            margin: 16px 32px;

            img {
                max-width: 65%;
            }
        }
    }
}

.light-theme {
    .client-shape {
        box-shadow: 0 4px 20px 0 rgba(165, 185, 210, 0.5);
    }
}

.dark-theme {
    .client-shape {
        box-shadow: 0 4px 16px 0 #000000;
    }
}
