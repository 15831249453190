//navbar style

/*start larg screens navbar style*/
.lg-nav {
    position: fixed;
    top: 0;
    width: 100%;
    padding: 24px 0;
    z-index: 7;
    transition: 0.3s;

    .container {
        max-width: unset;
        padding: 0 70px;
    }

    .brand {
        font-size: 1.3rem;
        font-weight: bold;
        color: $secondary-color;
    }

    .navigation-links {
        list-style: none;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .scrollactive-nav {
            display: flex;
            align-items: center;
        }

        li {
            display: inline-block;
            margin-left: 20px;

            a {
                color: $white-color;
                font-size: 1.125rem;
                font-weight: bold;
                position: relative;

                &.scrollactive-item::after {
                    content: "";
                    position: absolute;
                    width: 1px;
                    margin: 0 12px;
                    top: 0px;
                    height: 32px;
                    background-color: $primary-color;
                    opacity: 0.6;
                }

                .v-icon {
                    color: inherit;
                    text-shadow: unset !important;
                }

                &:hover {
                    // animation: nav-link-hover 0.5s forwards;
                    font-weight: 800;
                    text-shadow: 2px 2px 7px $secondary-light;

                    .v-icon {
                        animation: nav-link-hover 0.5s;
                        color: $secondary-light;
                    }
                }
            }
        }
    }

    &.scrolled {
        transform: translateY(-100%);

        &.sticky {
            transform: translateY(0);
            box-shadow: 0px 3px 6px #00000029;
        }

        .navigation-links {
            height: 100%;
            li {
                a {
                    &.is-active {
                        color: $dark-bg;
                    }
                }
            }
        }
    }

    &:not(.home-nav) {
        position: relative;

        .scrollactive-item {
            display: none;
        }
    }
}
/*end larg screens navbar style*/

/*start small screens navbar style*/
.sm-nav {
    position: fixed;
    top: 0;
    width: 100%;
    padding: 16px;
    z-index: 7;
    transition: 0.3s;
    display: flex;
    justify-content: space-between;
    align-items: center;

    a {
        flex: none;
    }

    .v-icon,
    .brand {
        font-size: 16px;
        font-weight: bold;
        color: $secondary-color;
        vertical-align: middle;
    }

    .switch-theme-sm {
        width: 38px !important;
        height: 36px;

        span {
            top: 0;
            left: 0;

            &::after {
                position: relative;
                top: 0;
                left: 0 !important;
            }
        }
    }

    &.scrolled {
        background-color: $primary-dark;
    }

    &:not(.home-nav) {
        position: relative;

        .scrollactive-item {
            display: none;
        }
    }
}
/*end small screens navbar style*/

/*start switch theme button*/
.switch-theme-sm,
.switch-theme-lg {
    display: block;
    width: fit-content;
    background-color: transparent !important;

    .switch-base {
        transition: 0.6s;
    }
}

.switch-theme-lg {
    position: relative;
    transition: 0.6s;
    width: 120px;
    height: 46px;

    span {
        position: absolute;
        top: 0;
        left: 0;
        width: max-content;
        display: inline-block;
        transition: 0.6s;

        &::after {
            display: block;
            position: absolute;
            top: 6px;
            width: max-content;
            right: 6px;
            transition: 0.6s;
        }

        &.dark-switch-container {
            opacity: 1;

            &::after {
                content: url("../images/circle-dark-lg.svg");
            }
        }

        &.light-switch-container {
            opacity: 0;

            &::after {
                content: url("../images/circle-light-lg.svg");
            }
        }
    }

    &.light-theme {
        span {
            &::after {
                right: 75px;
            }

            &.dark-switch-container {
                opacity: 0;
            }

            &.light-switch-container {
                opacity: 1;
            }
        }
    }
}
/*end switch theme button*/

/*start navigation drawer*/
.v-navigation-drawer {
    background-color: $drawer-dark-bg !important;

    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.15;
        background-image: url("../images/icon4.svg");
        background-position: bottom;
        background-size: contain;
        background-repeat: no-repeat;
        z-index: -1;
    }

    .v-list-item__icon {
        font-size: 16px;
        margin: 19px 0;
        margin-right: 16px !important;

        .v-icon {
            color: $white-color;
        }
    }

    .v-list-item__title {
        color: $white-color !important;
        font-size: 14px;
        font-weight: 500;
    }

    .brand {
        .v-icon,
        .v-list-item__title {
            font-size: 18px;
            color: $secondary-color !important;
        }
    }

    .secondary-divider {
        border-color: $secondary-color !important;
    }

    .v-list {
        .v-list-item__icon {
            .v-icon {
                color: $secondary-color !important;
                font-size: 8px !important;
            }
        }
    }
}
/*end navigation drawer*/

.light-theme {
    .v-navigation-drawer {
        background-color: $white-color !important;
    }

    .v-list-item__title {
        color: $secondary-color !important;
    }
}

///////// rtl style ///////
.rtl {
    /*start larg screens navbar style*/
    .lg-nav {
        .navigation-links {
            padding-left: 0;

            li {
                margin-right: 28px;
                margin-left: unset;
            }
        }
    }
    /*end larg screens navbar style*/

    /*start navigation drawer*/
    .v-navigation-drawer {
        .v-list-item__icon {
            margin-left: 16px !important;
            margin-right: unset !important;
        }

        .arrow-back {
            transform: rotate(180deg);
        }
    }
    /*end navigation drawer*/
}
