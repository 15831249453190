/*
** about us section style
*/
.about-us {
    @media (min-width: 960px) {
        margin-top: 136px;
    }

    /*start about us content*/
    .about-us-content {
        position: relative;

        @media (min-width: 960px) {
            padding-left: 60px;
        }

        @media (max-width: 959px) {
            margin-top: 40px;
        }

        .about-us-header {
            display: flex;
            span {
                display: inline-block;
            }

            h2 {
                margin-left: 32px;
            }

            img {
                order: -1;
                transform: rotateY(180deg) !important;
                width: 109px;
                height: 90px;
                margin-top: 16px;

                @media (min-width: 960px) {
                    margin-top: -42px;
                }
            }
        }

        .about-us-text {
            display: flex;
            align-items: flex-end;
            margin-top: 16px;
            margin-bottom: 60px;

            p {
                @extend .display-text;
                margin-bottom: 0;

                span {
                    color: $accent-color;
                    font: normal normal bold 34px/46px Cairo;
                    letter-spacing: -0.54px;
                }

                @media (max-width: 959px) {
                    font-size: 16px;
                    text-align: center !important;
                }
            }

            img {
                transform: rotateY(180deg) !important;

                @media (max-width: 959px) {
                    margin-bottom: -54px;
                }
            }
        }
    }
    /*end about us content*/
}

.light-theme {
    .founder-info img {
        border: 4px solid $white-color;
        box-shadow: 0 4px 20px 0 #a5b9d2;
    }
}

.dark-theme {
    .founder-info img {
        border: 4px solid #202a76;
        box-shadow: 0 4px 20px 0 #000000;
    }

    .about-us {
        background-color: #18184e;
    }
}

/////// rtl style //////
.rtl {
    .about-us {
        direction: ltr;

        .about-us-content {
            position: relative;

            .about-us-header {
                justify-content: flex-end;
                text-align: right;

                h2 {
                    margin-right: 32px;
                }

                img {
                    order: 1;
                }
            }

            .about-us-text {
                p {
                    direction: rtl;
                    text-align: right;
                    line-height: 1.9;

                    @media (min-width: 960px) {
                        padding-left: 30px;
                        padding-right: 100px;
                    }
                }

                img {
                    order: -1;
                }
            }

            img {
                transform: rotateY(0deg) !important;
            }
        }
    }
}
