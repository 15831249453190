/*
** main header style
*/

.main-header {
    position: relative;
    padding-top: 62px;

    .large-header-bg {
        transform: scaleX(-1);
        transition: 0.6s;
    }

    .intro {
        position: absolute;
        top: 0;
        margin-top: 9vw;

        .intro-logo {
            width: 152px;
            height: 209px;

            @media(min-width: 960px) and (max-width: 1263px) {
                width: 100px;
                height: auto;
            }
        }

        h1 {
            font-size: 2.4rem !important;
            font-weight: bold;
            text-transform: uppercase;
            color: $secondary-color;
            line-height: 1.89;
            margin: 8px 0;
        }

        p {
            color: $secondary-dark;
            font-size: 1rem;
            span {
                display: inline-block;
            }
        }

        .v-btn {
            margin-top: 0.88rem;
            min-width: 259px;
            height: 66px !important;
            font-size: 1rem;
            font-weight: bold;
            box-shadow: none !important;
            font-size: 16px;
            background-image: linear-gradient(to right, #2365aa, #1e9cd0);
            border-radius: 44px;
        }
    }

    @media(max-width: 959px) {
        min-height: 620px;
        
        .large-header-container {
            display: none;
        }

        .small-header-bg {
            position: relative;
            width: 100%;
            height: auto;
            margin-top: -60px;
            transition: 0.6s;
        }

        .intro {

            position: absolute;
            top: 100px;
            left: 0;
            width: 100%;
            text-align: center;
            margin-top: 0;

            .intro-logo {
                width: 35vw;
                height: auto;
            }

            h1 {
                font-size: 6vw !important;
            }

            p {
                font-size: 3vw;
                font-weight: 600;
                line-height: 2.13;
                letter-spacing: -0.13px;
            }

            .v-btn{
                min-width: 40vw;
                font-size: 4vw;
                height: 40px !important;
            }
        }

    }

    @media(min-width: 960px) {
        min-height: 436px;

        .small-header-bg {
            display: none;
        }

        .large-header-container {
            position: relative;
            width: calc(100% - 84px);
            height: auto;
            margin: 0 auto;
            margin-top: -65px;

            .large-header-bg {
                width: 100%;
            }

            .switch-theme-lg {
                width: 172.5px
            }

            p {
                display: block;
                margin: 16px auto;
                position: relative;
                text-align: center;
                margin-top: 92px;
            }
        }

        .intro {

            padding: 0 70px;

            p {
                font-weight: 600;
                line-height: 1.79;
                letter-spacing: -0.15px;
            }
        }

        .switch-theme-sm {
            display: none;
        }
    }

}

.rtl {
    .main-header {

        .header-bg-container {
            left: 0;
            right: unset;
        }

        .large-header-bg {
            transform: unset;
        }

        .intro {

            @media (min-width: 960px) {
                text-align: right;
            }

            h1 {
                /* line-height: 2; */

                span {
                    &:last-child {
                        display: none;
                    }

                    &:first-child {
                        margin-bottom: 24px;
                        font-weight: 900;
                    }
                }
            }
 
        }

        .header-img-container {
            left: 0;
            right: unset;

            .header-img {
                @media (max-width: 960px) {
                    margin-right: -45vw;
                    margin-left: 0;
                }
            }
        }

    }
}