/*
** social media labels fixed in the bottom right
*/

.social-media-container {
    display: inline;
    direction: rtl;
    position: fixed;
    right: 0;
    bottom: 20%;
    z-index: 6;

    a {
        display: flex;
        width: 56px;
        height: 56px;
        background-color: $accent-color;
        flex-direction: column;
        justify-content: center;
        margin-top: 8px;
        border-radius: 50% 0 0 50%;
        transition: 0.3s;

        &:hover {
            width: 66px;
        }

        .fa-w-14, .fa-w-10, .fa-w-16 {
            font-size: 24px;
            color: $white-color;
            margin: auto;
        }
    }
}

///// rtl style ///// 
.rtl {
    .social-media-container {
        direction: ltr;
        left: 0;
        right: unset;

        a {
            border-radius: 0 50% 50% 0;
        }
    }
}