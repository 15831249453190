/*
** privacy policy page style
*/

.privacy-policy-container {
    height: 100%;
    background-color: $white-color;

    .about-swave {
        margin: 100px 0;
    }

    h3 {
        color: $secondary-color;
        font-size: 21px;
        font-weight: bold;
    }

    p {
        font-size: 14px;
        color: $dark-bg;
    }

    ol, ul {
        font-size: 14px;
        color: $dark-bg;
        margin-bottom: 16px;
    }

    .main-footer {
        padding-top: 50px;
        .devider {
            display: none;
        }
    }
}

.dark-theme {
    .privacy-policy-container {
        background-color: transparent;
    }
}