/*
** about swave component
*/

.about-swave {
    border-radius: 16px;
    box-shadow: 0 28px 350px 0 rgba(0, 0, 0, 0.05);
    background-color: $white-color;
    padding: 32px;
    padding-bottom: 16px;

    @media (max-width: 960px) {
        text-align: center;
    }

    .about-swave-logo {
        margin-right: 32px;
        width: 109px;
    }

    h3 {
        font-size: 21px;
        color: $secondary-color;
        margin-bottom: 16px;
    }

    p {
        font-size: 14px;
        font-weight: 600;
        line-height: 2.63;
        color: $dark-bg;
    }
}

.dark-theme {
    .about-swave {
        background-color: $input-bg-dark;
    }
}

/////// rtl style ///////
.rtl {
    .about-swave {
        .about-swave-logo {
            margin-left: 32px;
            margin-right: unset;
        }
    }
}
