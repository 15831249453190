// golbal styles for all application
html {
    scroll-behavior: smooth;

    @media (max-width: 959px) {
        font-size: 12px;
    }

    @media (min-width: 960px) and (max-width: 1263px) {
        font-size: 14px;
    }

    @media (min-width: 1264px) and (max-width: 1903px) {
        font-size: 16px;
    }

    @media (min-width: 1904px) {
        font-size: 18px;
    }
}
body {
    font-family: $font-family !important;
    text-transform: capitalize;
    overflow: hidden;

    .v-application {
        font-family: inherit !important;
    }

    &.rtl {
        direction: rtl;

        .v-btn {
            letter-spacing: normal;
        }

        .back-icon {
            transform: rotate(180deg);
        }
    }

    .theme--light.v-application {
        background-color: inherit;
        transition: 0.6s;
    }

    a {
        text-decoration: none !important;
    }
}

[v-cloak] {
    display: none;
}

.col,
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-auto {
    padding: 0 8px;
}

.container {
    padding: 0;

    @media (max-width: 960px) {
        padding: 0 32px;
    }

    @media (min-width: 960px) and (max-width: 1264px) {
        max-width: 940px;
    }
}

.custom-container {
    padding: 0 32px;
    @media (min-width: 960px) {
        padding: 0 170px;
    }
}

.justify-content-center {
    justify-content: center;
}

.v-btn {
    border-radius: 8px;
    height: 48px !important;
}

.section-title {
    font-size: 2.5rem;
    font-weight: 900;
    line-height: 1.4;
    letter-spacing: -0.5px;
    color: $secondary-color;
    text-transform: uppercase;

    span {
        display: block;
    }
}

.display-text {
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1.77;
    letter-spacing: -0.21px;
    transition: 0.6s;
}

.primary-text {
    font-size: 1rem;
    font-weight: bold;
    line-height: 2.56;
    letter-spacing: -0.15px;
    transition: 0.6s;
}

.primary-small-text {
    font-size: 0.88rem;
    font-weight: bold;
    line-height: 2.19;
    letter-spacing: -0.13px;
    transition: 0.6s;
}

.dark-theme {
    background-color: $dark-bg !important;

    .v-text-field--filled > .v-input__control > .v-input__slot {
        background-color: $input-bg-dark;
    }

    .v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
        > .v-input__control
        > .v-input__slot
        fieldset {
        color: $input-bg-dark !important;
    }

    .display-text,
    .primary-text,
    .primary-small-text {
        color: $secondary-color;
    }

    nav.scrolled {
        background-color: $primary-color;
    }
    .feature-card figure,
    .project-link {
        background-color: $primary-color;
        color: $secondary-color;
    }

    .header-lbg {
        display: none;
    }
}

.light-theme {
    background-color: $light-bg !important;

    .v-text-field--filled > .v-input__control > .v-input__slot {
        background-color: $input-bg-light;
    }

    .v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
        > .v-input__control
        > .v-input__slot
        fieldset {
        color: $input-bg-light !important;
    }

    .display-text {
        color: $display-dark;
    }

    .primary-text,
    .primary-small-text {
        color: $primary-color;
    }

    nav.scrolled {
        background-color: $white-color;
    }

    .feature-card figure,
    .project-link {
        background-color: #fefdfd;
        color: $display-dark;
    }

    .v-input,
    .v-input input,
    .v-input textarea {
        color: $display-dark !important;
    }

    .lg-nav .navigation-links li a {
        color: $primary-color;
    }

    .header-dbg {
        display: none;
    }
}

.devider {
    width: 100%;
    height: 4px;
    background-image: linear-gradient(
        to right,
        transparent 50%,
        $accent-color 50%
    );
    background-size: 25px 100%;
    background-repeat: repeat;

    &.top-center-devider {
        width: 50%;
        margin: 0 auto;
        margin-bottom: 32px;

        @media (max-width: 959px) {
            width: 100%;
        }
    }
}

.view-all-link {
    font-size: 18px;
    font-weight: 600;
    color: $accent-color !important;

    &:hover {
        animation: view-all-hover 0.3s;
    }
}

.switch-theme-sm {
    position: relative;
    width: 100% !important;
    transition: 0.6s;

    a {
        width: 65px !important;
    }

    span {
        position: absolute;
        width: max-content;
        display: inline-block;
        transition: 0.6s;

        &::after {
            display: block;
            position: absolute;
            top: 6px;
            width: max-content;
            transition: right 0.5s ease 0s;
            transition: left 0.5s ease 0s;
            right: 12px;
            transition: 0.6s;
        }

        &.dark-switch-container {
            opacity: 1;

            &::after {
                content: url("../images/circle-dark-sm.svg");
            }
        }

        &.light-switch-container {
            opacity: 0;

            &::after {
                content: url("../images/circle-light-sm.svg");
            }
        }
    }

    &.light-theme {
        span {
            &::after {
                // right: 104px;
            }

            &.dark-switch-container {
                opacity: 0;
            }

            &.light-switch-container {
                opacity: 1;
            }
        }
    }

    p {
        display: block;
        width: 100%;
        margin: 16px auto;
        position: absolute;
        text-align: center;
        margin-top: 32px;
    }
}
