/*
** our projects section style
*/

.our-projects {
    padding: 50px 0;

    /*start our projects header*/
    .projects-header {
        display: flex;
        justify-content: space-between;

        span {
            display: inline-block;
        }

        a {
            align-self: flex-end;
        }
    }
    /*end our projects header*/

    /*start our projects cards*/
    .projects-cards {
        margin-top: 16px;
        float: left;

        .project-card {
            position: relative;
            overflow: hidden;
            line-height: 0;
            cursor: pointer;

            @media (min-width: 960px) {
                width: 50%;
                display: inline-block;
                float: left;
            }

            @media(min-width: 960px) and (max-width: 1263px) {
                height: 215px;
            }

            @media(min-width: 1264px) and (max-width: 1903px) {
                height: 300px;
            }

            @media(min-width: 1904px) {
                height: 460px;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            .project-overlay {
                display: none;
                position: absolute;
                top: 50%; 
                left: 50%;
                transform: translate(-50%, -50%);
                width: 600vw;
                height: 600vw;
                border-radius: 50%;
                background-color: rgba($primary-color, 0.7);
                animation: project-overlay 0.3s;
            }

            .project-overlay-text {
                display: none;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                flex-direction: column;
                justify-content: space-between;
                transition: 1s;

                div {
                    padding: 16px;
                    color: $white-color;

                    @media (max-width: 960px) {
                        padding: 0.6rem;
                    }
                }

                .project-links {
                    display: flex;
                    justify-content: flex-end;
                    margin-bottom: 0;
                    padding-bottom: 0;

                    .amimated-icons {

                        display: flex;
                        flex-direction: column;

                        .v-icon {
                            color: $white-color;
                            margin-bottom: 8px;
                            font-size: 32px;

                            @media (max-width: 960px) {
                                font-size: 24px;
                            }
    
                            &:first-child {
                                animation: project-overlay-links 0.3s;
                            }
    
                            &:last-child {
                                animation: project-overlay-links 0.6s;
                            }
                        }
                    }

                    .social-sharing {
                        display: none; 
                        padding: 0;
                        padding-bottom: 8px;

                        &.show {
                            display: flex;
                            align-items: flex-end;
                        }

                        .svg-inline--fa{
                            display: inline-block;
                            font-size: 24px;
                            color: $white-color;
                            margin-left: 16px;
                            vertical-align: middle;
                        }
                    }

                }

                .project-name {
                    font-size: 32px;
                    font-weight: 500;
                    animation: project-overlay-name 0.3s;
                    line-height: 1;

                    @media (max-width: 960px) {
                        font-size: 1rem;
                    }
                }
            }

            &:hover {
                .project-overlay, .project-overlay-text {
                    display: flex;

                    @media(max-width: 959px) {
                        display: none;
                    }
                }
            }
        }

        &.odd-grid {
            >div:first-child {
                width: 100%;
                height: 340px;

                @media (max-width: 959px){
                    height: 182px;
                }

                img {
                    object-fit: cover;
                }
            }
        }
    }
    /*end our projects cards*/

    .see-more {
        display: flex;
        width: 200px;
        height: 65px !important;
        border-radius: 0;
        font-size: 24px;
        font-weight: bold;
        margin: 22px 0;
        margin-inline-start: auto;

        @media (max-width: 600px) {
            width: 100%;
        }
    }

    &.all {
        padding: 0;

        .projects-cards {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            margin-top: 0;
            direction: inherit !important;

            .project-card-container {

                .project-card {
                    width: 100%;
                    border-radius: 8px;
                    margin-bottom: 8px;
                    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.16);

                    img, .project-overlay {
                        border-radius: inherit;
                    }

                }

                p {
                    padding: 4px 0;
                }

                @media(min-width: 700px) {
                    width: calc(50% - 16px);
                    margin-bottom: 32px;
                }
            }
        }
    }
}

.our-projects-container {

    .projects-page-header {
        display: flex;
        padding-top: 32px;
        justify-content: space-between;

        .v-btn {
            width: 28px;
            height: 28px !important;
            border-radius: 4px;
            margin: 0 !important;
        }

        .section-title {
            margin-top: -8px;
        }

        .switch-theme-sm {
            width: 38px !important;
            height: 36px;

            span {
                top: 0;
                left: 0;

                &::after {
                    position: relative;
                    top: 0;
                    left: 0 !important;
                    right: unset;
                }
            }
        }
    }
}

.projects-footer {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-bottom: -50px;
    padding-bottom: 50px;


    p {
        margin: 50px 0;
    }

    .v-btn {
        padding: 0 32px;
        height: 53px;
        border-radius: 0;
        font-size: 12px;
        font-weight: bold;
    }

    @media(min-width: 960px) {
        &::before, &::after {
            content: '';
            position: absolute;
            top: 0;
            width: 29%;
            height: 100%;
            background-size: contain;
            background-repeat: no-repeat;
        }

        &::before {
            left: 0;
            background-image: url("../images/projects-footer-left.png");
            background-position-x: left;
            background-position-y: bottom;
        }

        &::after {
            right: 0;
            background-image: url("../images/projects-footer-right.png");
            background-position: right;
            background-position-x: right;
            background-position-y: top;
        }
    }
}


////// rtl style ////// 
.rtl {
    .our-projects {

        /*start our projects cards*/
        .projects-cards {

            direction: ltr;
        }
        /*end our projects cards*/
    }
}