// Body


// Typography
$font-family: 'Cairo', sans-serif;

// Colors
$primary-color: #202a76;
$primary-dark: #1a0d3e;
$display-dark: #1f1957;
$input-bg-dark: #18184e;
$input-bg-light: #f3f3f3;
$secondary-color: #3dbcd4; //headline light + dark
$secondary-dark: #2365aa;
$secondary-light: #80DEEA;
$accent-color: #a12272;
$dark-bg: #15113a;
$light-bg: #ECF0F5;
$white-color: #FFF;
$gray-color: #a8a8a8;
$apricot-color: #EF968C;
$morning-color: #68D6C8;
$golden-color: #F3C447;
$drawer-dark-bg: #182057;