/*
** advanced contact us page style
*/

.advanced-contact-us-container {
    height: 100%;

    /*start page header*/
    .contact-us-header {
        padding: 32px 0;
        color: $white-color;

        @media (max-width: 959px) {
            text-align: center;
        }

        h1 {
            font-size: 18px;
            font-weight: bold;
        }

        .devider {
            margin: 32px 0;
        }

        h2 {
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 16px;
        }

        p {
            font-size: 14px;

            &.contac-us {
                color: $secondary-color;
                margin-top: 64px;
                margin-bottom: 32px;
                span {
                    margin-right: 32px;
                }

                strong {
                    margin-right: 4px;
                }
            }
        }

        .v-btn {
            font-size: 16px;
            font-weight: bold;
        }

        .header-img {
            max-width: 100%;

            @media (min-width: 960px) {
                margin-left: 75px;
            }
        }
    }
    /*end page header*/

    /*start about swave*/
    .about-swave {
        margin-top: 96px;
    }
    /*end about swave*/

    /*start advanced contact us form*/
    .advanced-contact-us {
        margin-top: 64px;

        .form-legend {
            font-size: 18px;
            font-weight: bold;
            color: $secondary-color;
            margin: 16px 0;
            margin-top: 32px;
        }

        .v-input {
            margin-bottom: 16px;
            border-radius: 8px;

            input,
            textarea {
                color: $dark-bg;
            }
        }

        .v-radio {
            .v-label {
                color: inherit;
            }
        }

        .v-label {
            color: $secondary-color;
        }

        .v-icon {
            color: inherit !important;
        }

        .form-footer-container {
            margin: 50px 0;

            .form-footer {
                display: flex;
                justify-content: space-between;
                padding: 16px 0;
                align-items: center;

                p {
                    font-size: 16px;
                    font-weight: bold;
                    margin-bottom: 0;
                }

                .v-btn {
                    font-weight: bold;
                }

                @media (max-width: 959px) {
                    flex-direction: column;
                    text-align: center;

                    .v-btn {
                        margin-top: 16px;
                    }
                }
            }
        }
    }
    /*end advanced contact us form*/

    .main-footer {
        .devider {
            display: none;
        }
    }
}

/*start response dialog*/
.response-dialog {
    position: relative;
    background-color: $white-color;
    padding: 32px;
    text-align: center;
    border-radius: 16px;
    box-shadow: 0 28px 350px 0 rgba(0, 0, 0, 0.05);

    .close-icon {
        width: unset;
        position: absolute;
        top: 5%;
        right: 5%;
        cursor: pointer;
    }

    img {
        width: 40%;
    }

    h3 {
        font-size: 16px;
        font-weight: bold;
        color: $secondary-color;
    }

    p {
        font-size: 14px;
        color: $dark-bg;
        margin-top: 8px;
    }

    .v-btn {
        font-weight: bold;
        width: unset;
        padding-left: 16px;
        padding-right: 16px;
    }
}
/*end response dialog*/

////// rtl style ///////
.rtl {
    .advanced-contact-us-container {
        /*start page header*/
        .contact-us-header {
            p {
                &.contac-us {
                    span {
                        margin-left: 32px;
                        margin-right: unset;
                    }

                    strong {
                        margin-left: 4px;
                        margin-right: unset;
                    }
                }
            }

            .header-img {
                @media (min-width: 960px) {
                    margin-right: 75px;
                    margin-left: unset;
                }
            }
        }
        /*end page header*/

        .v-label {
            left: auto !important;
            right: 0 !important;
            transform-origin: top right !important;
            text-align: right;
        }

        .v-input__slot {
            fieldset {
                padding-right: 8px;

                legend {
                    text-align: right;
                }
            }
        }

        .v-textarea.v-text-field--enclosed .v-text-field__slot {
            margin-right: 0 !important;
        }

        .v-messages__message {
            text-align: right;
        }

        .v-input--selection-controls__input {
            margin-left: 8px;
            margin-right: unset;
        }
    }

    /*start response dialog*/
    .response-dialog {
        .close-icon {
            left: 5%;
            right: unset;
        }
    }
    /*end response dialog*/
}
