/*
** our technologies section style
*/

.section-title {
    margin-bottom: 32px;
}

.technologies {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 400px;
    background-image: url("../images/tech-bg.jpg");
    background-size: cover;
    background-attachment: fixed;

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($primary-dark, 0.9);
    }

    .slider-container {
        width: 100%;
        padding: 16px 0;
        background-color: rgba($white-color, 0.1);
        z-index: 0;

        * {
            outline: none !important;
        }

        .slick-track {
            display: flex;

            .slick-slide {
                display: flex;
                height: auto;
                align-items: center;
                justify-content: center;
                img {
                    width: 90%;
                    margin: 0 auto;
                }
            }
          }
    }

    .devider {
        margin: 50px 0;
    }
}