/*
** main footer style
*/
.main-footer {
    font-size: 14px;
    font-weight: 500;
    color: $gray-color;
    padding-bottom: 50px;

    @media (max-width: 960px) {
        text-align: center;
    }

    .devider {
        margin: 50px 0;
    }

    .copyrights {

        img {
            width: 21px;
            height: auto;
            margin-right: 8px;
            vertical-align: middle;
        }
    }

    .footer-links {
       /*  display: flex;
        justify-content: space-between;
        width: max-content; */
        list-style: none;

        @media(max-width: 960px) {
            margin-top: 16px;
        }

        li {
            display: inline-block;
            padding: 0 8px;
            a {
                color: $gray-color;

                &:hover {
                    animation: footer-link-hover 0.4s;
                    color: $secondary-color;
                }
            }
        }
    }
}

//////// rtl style //////// 
.rtl {
    .main-footer {
        .copyrights {
            img {
                margin-left: 8px;
                margin-right: unset;
            }
        }

        .footer-links {
            padding-left: 0;
            @media(min-width: 960px) {
                text-align: left;
            }
            
        }
    }
}