/*
** contact us section style
*/

.contact-us {

    .devider {
        margin: 50px 0;
    }

    .custom-container {
        @media(min-width: 960px) {
            padding: 0 100px;
        }
    }

    /*start contact us intro*/
    .contact-us-intro {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        height: 100%;
        
        img {
            max-width: 100%;
            width: 350px;
            margin: 0 auto;
            margin-top: 8px;

            @media (max-width: 960px) {
                margin-bottom: 50px;
            }
        }

        h3 {
            font-size: 24px;
            font-weight: 900;
            color: $accent-color;
        }

        p {
            font-size: 14px;
            font-weight: 500;
            color: $secondary-color;
            margin-top: 16px;
            margin-bottom: 0;

            span {
                display: inline-block;
            }
        }
    }
    /*end contact us intro*/

    /*start contact us form*/

    .contact-us-form {
        @media (max-width: 959px) {
            margin-top: 50px;
        }
    }

    .v-input {
        margin-top: 16px;
        border-radius: 8px
    }


    .theme--light.v-label{
        color: $secondary-color;
    }

    .theme--light.v-input, .theme--light.v-input input, .theme--light.v-input textarea {
        color: $white-color;
    }

    .v-text-field--filled.v-input--dense>.v-input__control>.v-input__slot, .v-text-field--full-width.v-input--dense>.v-input__control>.v-input__slot, .v-text-field--outlined.v-input--dense>.v-input__control>.v-input__slot {
        min-height: unset;
        height: 48px;
    }

    .submit-container {
        @media (max-width: 959px) {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
    }

    .submit-btn {
        margin-top: 16px;
        @media (min-width: 960px) {
            margin-left: 8px;
        }
        
    }
    /*end contact us form*/
}

////////// rtl style  ///////////// 
.rtl {
    .contact-us {
        /*start contact us intro*/
        .contact-us-intro {
            @media (min-width: 960px) {
                padding-left: 50px;
                padding-right: 0;
            }
        }
        /*end contact us intro*/

        .v-label {
            left: auto !important;
            right: 0 !important;
            transform-origin: top right !important;
        }

        .v-input__slot {

            fieldset {
                padding-right: 8px;

                legend {
                    text-align: right;
                }
            }
        }

        .v-textarea.v-text-field--enclosed .v-text-field__slot {
            margin-right: 0 !important;
        }

        .v-messages__message {
            text-align: right;
        }

        .submit-btn {
            margin-left: 0;

            @media (min-width: 960px) {
                margin-right: 8px;
            }
            
        }
    }
}